import { Box } from '@chakra-ui/layout'
import { SimpleGrid } from '@chakra-ui/react'
import { FaLinkedinIn, FaGithub } from 'react-icons/fa';

export const Links = () => {
    return (
        <Box>
            <SimpleGrid columns={2} spacing={10}>
                <Box
                    fontSize="3xl"
                    color="gray.200"
                    _hover={{ color: "#0072b1" }}
                    cursor="pointer"
                    onClick={() => window.open("https://www.linkedin.com/in/jackcorton/", '_blank')}
                >
                    <FaLinkedinIn />
                </Box>
                <Box
                    fontSize="3xl"
                    color="gray.200"
                    _hover={{ color: "#6e5494" }}
                    cursor="pointer"
                    onClick={() => window.open("https://github.com/c20rtn", '_blank')}
                >
                    <FaGithub />
                </Box>
            </SimpleGrid>
        </Box>
    )
}
