import './App.css';
import { Container, VStack } from '@chakra-ui/react';
import MeInfo from './components/meInfo';
import { Employment } from './components/employment';
import { Projects } from './components/projects';
import { School } from './components/school';
import { Links } from './components/links';

function App() {
  return (
    <div className="App">
      {/* <StartPage/> */}
      <Container
        minH="full"
        display="flex"
        textAlign='left'
      >

        <VStack
          w="100vh"
          minH="100vh"
          py="10"
          textAlign="left"
          spacing="30px"
        >
          <MeInfo />
          <School />
          <Employment />
          <Projects />
          <Links />
        </VStack>

      </Container>

    </div>
  );
}

export default App;
