import { Box, Heading, Text } from '@chakra-ui/layout'

const MeInfo = () => {
    return (
        <Box w="full" textAlign="left">
            <Heading
                color="gray.200"
                fontSize="6xl"
            >
                Jack Corton
            </Heading>
            <Text
                color="gray.200"
                fontSize="2xl"
            >
                Front-End Developer @ Guildhawk
            </Text>
        </Box>
    )
}

export default MeInfo