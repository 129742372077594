import { Box, Button, Circle, Grid, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import { FaGithub } from 'react-icons/fa'

interface Props {
    title: string
    contents: string
    url: string
}

export const ProjectBox = ({ title, contents, url }: Props) => {
    return (
        <Box
            border="2px"
            borderColor="gray.200"
            borderRadius="5"
            boxShadow="lg"
            color="gray.200"
            my="20px"
        >
            <Box
                borderBottom="1px"
                borderColor="gray.200"
                borderTopLeftRadius="20"
                borderTopRightRadius="20"
                w="100%"
                p={4}
                color="gray.200">
                <Grid templateColumns="repeat(2, 1fr)">
                    <Heading fontWeight="normal" fontSize="xl">{title}</Heading>
                    <HStack marginLeft="auto">
                        <Circle size="20px" bg="green.200" border="1px" color="black"></Circle>
                        <Circle size="20px" bg="gray.200" border="1px" color="black"></Circle>
                        <Circle size="20px" bg="red.200" border="1px" color="black"></Circle>
                    </HStack>
                </Grid>
            </Box>
            <Box
                borderBottomLeftRadius="20"
                borderBottomRightRadius="20"
                p={4}
                w="100%">

                <VStack w="full">
                    <Text fontSize="md" color="gray.200">{contents}</Text>
                    <Button
                        placeSelf="flex-end"
                        onClick={() => window.open(url, '_blank')}
                        variant='outline'
                        leftIcon={<FaGithub />}
                        _hover={{ backgroundColor: "#6e5494", color: "white" }}
                    >
                        GitHub
                    </Button>
                </VStack>
            </Box>
        </Box>
    )
}
