import { Box, Heading, Text } from '@chakra-ui/layout'
import { ProjectBox } from './projectBox'

const projectList = [
    {
        title: "GAN Colourisation",
        contents: " My project regarding the colourisation of images with generator models of normal and GAN training. The models are pretrained in Python in Keras and Tensorflow, then converted to a Javascript model.",
        url: "https://github.com/c20rtn/Greyscale-Colourisation-GAN"
    }
]

export const Projects = () => {
    return (
        <Box w="full" textAlign="left">
            <Heading
                color="gray.200"
            >
                Projects
            </Heading>
            {projectList.map((x) => (
                <ProjectBox
                    title={x.title}
                    contents={x.contents}
                    url={x.url}
                />
            ))}
        </Box>
    )
}
