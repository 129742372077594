import { Box, Heading, Text } from '@chakra-ui/layout'

const history = [
    {
        name: "Sheffield Hallam University",
        deg: "BSc Computer Science",
        time: "Sept 2016 - May 2020",
        grade: "Grade: First Class Hons"
    },
]


export const School = () => {
    return (
        <Box w="full" textAlign="left">
            <Heading
                color="gray.200"
            >
                Education
            </Heading>
            {history.map((x) => (
                <Box borderLeft="2px" py="5px" px="15px" my="20px" borderColor="gray.200">
                    <Text color="gray.200" fontSize="xl">
                        {x.name}
                    </Text>
                    <Text color="gray.200" fontSize="lg">
                        {x.deg}
                    </Text>
                    <Text color="gray.300" fontSize="sm">
                        {x.grade}
                    </Text>
                    <Text color="gray.300" fontSize="sm">
                        {x.time}
                    </Text>
                </Box>
            ))}
        </Box>
    )
}
