import { Box, Heading, Text } from '@chakra-ui/layout'

const history = [
    {
        name: "Guildhawk",
        role: "Frontend Developer",
        time: "July 2020 - Present",
        loc: "Sheffield, United Kingdom"
    },
    {
        name: "Richlyn Systems (Placement)",
        role: "Junior Web Developer",
        time: "July 2018 - July 2019",
        loc: "Sheffield, United Kingdom"
    },
]

export const Employment = () => {
    return (
        <Box w="full" textAlign="left">
            <Heading
                color="gray.200"
            >
                Employment
            </Heading>
            {history.map((x) => (
                <Box borderLeft="2px" py="5px" px="15px" my="20px" borderColor="gray.200">
                    <Text color="gray.200" fontSize="xl">
                        {x.name}
                    </Text>
                    <Text color="gray.200" fontSize="lg">
                        {x.role}
                    </Text>
                    <Text color="gray.300" fontSize="sm">
                        {x.time}
                    </Text>
                    <Text color="gray.300" fontSize="sm">
                        {x.loc}
                    </Text>
                </Box>
            ))}
        </Box>
    )
}